<template>
  <div>
    <el-dialog
      title="查看绑定工位"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <el-card
        class="box-card"
        v-for="item in wpData"
        :key="item.workPlaceId"
        style="margin-top: 20px"
      >
        <div slot="header" class="clearfix">
          <span>工位名：{{ item.workPlace?item.workPlace.name:'未知' }}</span>
        </div>
        <div style="font-size: 13px; color: #999; margin-bottom: 10px">
          绑定步骤：
        </div>
        <div
          v-for="process in item.procesies"
          :key="process.id"
          class="text item"
          style="font-size: 8px"
        >
          {{ process.sortIndex }}: {{ process.name }}
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>

    <el-row :gutter="20" class="page-box task-list-page">
      <el-col>
        <Breadcrumb
          :home="false"
          :breadcrumbs="[
            {
              label: '产线',
              to: '/',
            },
          ]"
        />
      </el-col>
      <el-col>
        <el-card class="grid-card" shadow="never">
          <SchemaTable
            ref="schematableref"
            :schema="schema"
            :model="dataModel"
            :search="search"
            :filters="filters"
            :queryConf="queryConf"
            :hasReset="hasReset"
            :formConf="formConf"
            :canCreate="canCreate"
            :canEdit="false"
            :canDelete="false"
          >
            <template #actionBtns="{ row }">
              <el-link
                class="action-link"
                type="primary"
                @click="show(row)">
                查看
              </el-link>
            </template>
          </SchemaTable>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./productLine.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import axios from "axios";

export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      formConf: {
        getServerOptions: this.getServerOptions.bind(this),
      },
      wpData: {},
      dialogVisible: false,
      search: "",
      filters: ["name", "factoryId"],
      schema,
      hasReset: true,
      canCreate: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: uris.productLine,
        getListMap(item) {
          if (item.factory) item.factoryId = item.factory.name;
          item.createdTime = +new Date(item.created_at);
          if (item.order) item.orderName = item.order.name;
          if (item.workflow) item.workflowName = item.workflow.name;
          item.status = item.status === 1 ? "开启" : "关闭";
          return item;
        },
        getApi: `${uris.productLine}${attachId}`,
        getMap(res) {
          res.roleId = res.role.id;
          if (res.factory) res.factoryId = res.factory.id;
          return res;
        },
        deleteApi: `${uris.productLine}${attachId}`,
        createApi: uris.productLine,
        updateApi: `${uris.productLine}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    async optionsFormat(list, valueKey = "id", labelKey = "name") {
      if (!Array.isArray(list)) {
        return [];
      }
      let str = JSON.stringify(list)
        .replace(new RegExp(`"${valueKey}":`, "g"), '"value":')
        .replace(new RegExp(`"${labelKey}":`, "g"), '"label":');
      return JSON.parse(str);
    },
    async getServerOptions(scenario) {
      const optionMd = new DataModel({
        getApi: "/api/v1/role",
      });
      const res = await optionMd.get();
      let options = await this.optionsFormat(res.list);
      if (scenario === "create") {
        options = options.filter((item) => {
          if (item.value !== "3") {
            return true;
          }
        });
      }
      return options;
    },
    async handleClose() {
      this.dialogVisible = false;
    },
    async show(row) {
      this.productLineId = row.id;
      axios
        .get(`${uris.processWorkPlace}?productLineId=${this.productLineId}`)
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }

          this.wpData = res.data.data.list.map(factory => {
            // 考虑 procesies 列表项可能是 null
            if(factory.procesies.length) {
              factory.procesies = factory.procesies.filter(proc => !!proc);
            }
            return factory;
          });
          if (!this.wpData.length) {
            this.$message({
              type: "warning",
              message: "暂未绑定工位！",
            });
            return;
          }
          this.dialogVisible = true;

          console.log(this.wpData);
        });
    },
  },
};
</script>

<style scoped>
</style>